import React, { useEffect, useState } from "react";
import { PaymentMethod } from "../../../services/Api/PaymentMethod";
import zarinpal from "../../../zarinpal.svg";
import idpay from "../../../idpay.svg";
import moment from "moment";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { message, Switch } from "antd";

type PaymentImage = {
  [key: string]: string;
};

export const paymentImage: PaymentImage = {
  zarinpal: zarinpal,
  idpay: idpay,
};

export const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>();
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await PaymentMethod.getAll().json();
        if (!data) {
          return;
        }
        setPaymentMethods(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const changePaymentMethodStatus = async (id: string, checked: boolean) => {
    try {
      message.info(`${checked ? "فعال" : "غیر فعال"} سازی درگاه..`);
      const { data }: any = await PaymentMethod.edit({
        id,
        status: checked,
      }).json();
      if (!data) {
        return;
      }
      message.success(`درگاه با موفقیت ${checked ? "فعال" : "غیر فعال"} شد.`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="mt-3">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {paymentMethods &&
          paymentMethods?.map(({ name, id, updatedAt, isActive }: any) => (
            <div
              className="px-4 py-5 flex border border-gray-100 rounded"
              key={id}
            >
              <div className="payment-method-image ml-5 flex flex-col justify-center">
                <img src={paymentImage[name]} />
                <div className="flex justify-center items-center mt-4">
                  <Switch
                    defaultChecked={isActive}
                    onChange={(e) => changePaymentMethodStatus(id, e)}
                  />
                </div>
              </div>
              <div className="flex-auto flex flex-col">
                <div className="flex">
                  <span className="capitalize text-base font-medium mb-2 pl-2">
                    درگاه {name}
                  </span>
                </div>

                <span className="flex mb-3 py-2 px-3 bg-gray-50 text-left text-xs rounded-md select-all">
                  {id}
                </span>
                <span className="text-xs text-gray-500">
                  آخرین تغییر:
                  <TimeAgo
                    datetime={updatedAt}
                    locale="fa"
                    className="dono-dock-time px-1 mb-0"
                  />
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
