import { get, patch, post, destroy } from "../../config/api";

export const Bot = {
  getAll: ({ page = 1, limit = 30 }) => get(`bots?page=${page}&limit=${limit}`),
  getById: (id: string) => get(`bots/${id}`),
  editById: ({ id, payload }: { id: string; payload: any }) =>
    patch(`bots/${id}`, { json: { ...payload } }),
  add: (payload: any) => post(`bots`, { json: { ...payload } }),
  removeById: (id: string) => destroy(`bots/${id}`),
  getLogsById: ({
    id,
    page = 1,
    limit = 100,
  }: {
    id: string;
    page?: number;
    limit?: number;
  }) => get(`bots/${id}/logs?page=${page}&limit=${limit}`),
};
