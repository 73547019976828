import React from "react";
import { Tooltip } from "antd";
import moment from "moment";
import TimeAgo from "timeago-react";
import zarinpal from "../../zarinpal.svg";
import idpay from "../../idpay.svg";

export const Model = [
  {
    title: "شناسه",
    dataIndex: "id",
    key: "id",
    render: (id: string, row: any) => (
      <div className="flex items-center">
        {row?.paymentMethodId === "2a2e420a-2c39-42b8-b470-8572c89fe4ac" ? (
          <img src={zarinpal} className="w-6 h-6" />
        ) : (
          <img src={idpay} className="w-6 h-6" />
        )}
        <span className="block mr-2">{id}</span>
      </div>
    ),
  },
  {
    title: "شناسه خرید",
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "مبلغ پرداختی",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    render: (status: string) =>
      status === "PAID" || status === "PENDING" ? (
        <span className="bg-blue-50 rounded py-1 px-2 text-blue-500 uppercase text-xs font-weight-medium">
          در انتظار
        </span>
      ) : status === "COMPLETED" ? (
        <span className="bg-green-50 rounded py-1 px-2 text-green-500 uppercase text-xs font-weight-medium">
          موفق
        </span>
      ) : status === "CREATED" ? (
        <span className="bg-gray-50 rounded py-1 px-2 text-gray-500 uppercase text-xs font-weight-medium">
          جدید
        </span>
      ) : status === "FAILED" ? (
        <span className="bg-red-50 rounded py-1 px-2 text-red-500 uppercase text-xs font-weight-medium">
          ناموفق
        </span>
      ) : status === "CANCELED" ? (
        <span className="bg-yellow-50 rounded py-1 px-2 text-yellow-500 uppercase text-xs font-weight-medium">
          کنسل شده
        </span>
      ) : (
        <>{status}</>
      ),
    width: "9%",
  },
  {
    title: "تاریخ",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: Date) => (
      <Tooltip
        title={moment(createdAt).format("L - LT")}
        className="cursor-pointer"
      >
        <TimeAgo
          datetime={createdAt}
          locale="fa"
          className="dono-dock-time px-1 mb-0"
        />
      </Tooltip>
    ),
  },
];
