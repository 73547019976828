import React from "react";
import moment from "moment";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { Dropdown, Menu, Popconfirm, Tooltip } from "antd";
import {
  DotsHorizontalIcon,
  ExternalLinkIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
timeago.register("fa", fa);

export const Model = ({
  updateCardBalance,
}: {
  updateCardBalance: (id: string) => Promise<void>;
}) => [
  {
    title: "شناسه",
    dataIndex: "id",
    key: "id",
    width: "29%",
  },
  {
    title: "شماره کارت",
    dataIndex: "cardNumber",
    key: "cardNumber",
    width: "29%",
  },
  {
    title: "موجودی",
    dataIndex: "balance",
    key: "balance",
    render: (balance: number, row: any) => (
      <span
        className="text-left"
        style={{ direction: "ltr" }}
      >{`${balance} ${row?.currencyCode}`}</span>
    ),
  },
  {
    title: "اعتبار",
    dataIndex: "isValid",
    key: "isValid",
    render: (isValid: string) =>
      isValid || isValid === "true" ? (
        <span className="bg-green-50 rounded py-1 px-2 text-green-500 uppercase text-xs font-weight-medium">
          معتبر
        </span>
      ) : (
        <span className="bg-gray-50 rounded py-1 px-2 text-gray-500 uppercase text-xs font-weight-medium">
          نامعتبر
        </span>
      ),
  },
  {
    title: "ایجاد شده",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: Date) => (
      <Tooltip
        title={moment(createdAt).format("L - LT")}
        className="cursor-pointer"
      >
        <TimeAgo
          datetime={createdAt}
          locale="fa"
          className="dono-dock-time px-1 mb-0"
        />
      </Tooltip>
    ),
  },
  {
    title: "",
    render: (actions: any, row: any) => (
      <div className="flex justify-end items-center pl-4">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <a onClick={() => updateCardBalance(row?.id)}>آپدیت موجودی</a>{" "}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <DotsHorizontalIcon className="w-6 h-6 text-gray-400 cursor-pointer" />
        </Dropdown>
      </div>
    ),
  },
];
