import React, { useState, useEffect } from "react";
import { Input, message, Modal, Spin, Table } from "antd";
import { Order } from "../../services/Api/Order";
import { Model } from "./Model";
import { Route, Switch, useParams } from "react-router-dom";
import { RefreshIcon } from "@heroicons/react/outline";
import { Tabs } from "antd";
import { OrderAttachments } from "./OrderAttachment";
import { OrderBotLog } from "./OrderBotLog";

const sortOptions = [
  {
    title: "همه",
    value: "",
    singleOnly: true,
    default: true,
  },
  {
    title: "موفق",
    value: "COMPLETED",
  },
  {
    title: "اغما",
    value: "AWAITING_COMPLETED",
  },
  {
    title: "پرداخت شده",
    value: "PAID",
  },
  {
    title: "ناموفق",
    value: "FAILED",
  },
  {
    title: "دستی",
    value: "MANUALLY_FULFILLED",
  },
  {
    title: "نیازمند تایید",
    value: "POSSIBLY_COMPLETED",
  },
];

export const Orders = (props: any) => {
  const [data, setData] = useState<any[]>();
  const [query, setQuery] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<any>(
    sortOptions?.map((option) =>
      option?.default
        ? {
            ...option,
            active: true,
          }
        : { ...option, active: false }
    )
  );

  const [pagination, setPagination] = useState<{
    current: number;
    total: number;
  }>();

  const activeParams = params?.filter((p: any) => p?.active);

  const refreshOrders = async (
    forceGetPagination?: boolean | string | null,
    autoRefresh?: boolean
  ) => {
    try {
      setLoading(true);
      const activeParamsToString =
        activeParams.length &&
        activeParams?.map((param: any) => param?.value).join(",");
      const preparedParams =
        activeParamsToString && `status=${activeParamsToString}`;
      const { data, pagination: resPagination }: any = await Order.getAll({
        page: forceGetPagination ? 1 : pagination?.current || 1,
        params: preparedParams,
        query,
      }).json();
      if (!data) {
        return;
      }
      setData(data);
      if (autoRefresh) {
        return;
      }
      if (
        pagination?.current !== +resPagination.current ||
        forceGetPagination
      ) {
        setPagination({
          current: +resPagination?.current,
          total: resPagination?.total * 10,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!refreshOrders || !pagination) {
      return;
    }

    const automaticRefreshId = setInterval(() => refreshOrders(), 10000);

    return () => {
      clearTimeout(automaticRefreshId);
    };
  }, [pagination]);

  useEffect(() => {
    (async () => {
      try {
        await refreshOrders();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [pagination]);

  useEffect(() => {
    (async () => {
      try {
        await refreshOrders(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query]);

  useEffect(() => {
    (async () => {
      try {
        await refreshOrders("updateParams");
      } catch (e) {
        console.error(e);
      }
    })();
  }, [params]);

  const retryOrder = (id: string) => {
    (async () => {
      try {
        const { data }: any = await Order.retry(id).json();
        if (!data) {
          return;
        }
        message.success("با موفقیت انجام شد");
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const manualFullfill = (id: string) => {
    (async () => {
      try {
        const { data }: any = await Order.edit({
          id,
          payload: { status: "MANUALLY_FULFILLED" },
        }).json();
        if (!data) {
          return;
        }
        message.success("با موفقیت انجام شد");
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const manualRefund = (id: string) => {
    (async () => {
      try {
        const { data }: any = await Order.edit({
          id,
          payload: { status: "REFUNDED" },
        }).json();
        if (!data) {
          return;
        }
        message.success("با موفقیت انجام شد");
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handlePagination = (pageNumber: number) => {
    if (setPagination) {
      setPagination((prevState: any) => {
        if (typeof prevState !== undefined) {
          return {
            ...prevState,
            current: pageNumber,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (activeParams.length === 0) {
      setParams((prevState: any) =>
        prevState.map((p: any) => (p?.singleOnly ? { ...p, active: true } : p))
      );
    }
  }, [params]);

  const handleFilters = ({ value, singleOnly }: any) => {
    if (singleOnly) {
      return setParams((prevState: any) =>
        prevState.map((p: any) => {
          return { ...p, active: p.singleOnly };
        })
      );
    }
    setParams((prevState: any) =>
      prevState.map((p: any) => {
        if (p.singleOnly) {
          return { ...p, active: false };
        }
        if (p.value === value) {
          return { ...p, active: !p?.active };
        } else {
          return p;
        }
      })
    );
  };

  const handleSearch = (e: any) => {
    const { value } = e.target;
    if (!value) {
      setQuery(null);
    }
    setQuery(value);
  };

  return (
    <div>
      <h1 className="text-xl font-bold mb-3">سفارش ها</h1>
      <p className="text-muted pt-half text-gray-500 mb-8">
        آخرین سابسکریپشن های انجام شده توسط دونو در زیر لیست شده است.
      </p>
      <div className="border-t border-gray-100 -mx-10 mb-5" />
      <div className="flex flex-col lg:flex-row justify-between items-center mb-5">
        <div className="sort rounded-lg lg:rounded-full bg-gray-100 px-1 py-1.5 mb-4 lg:mb-0">
          {params?.map((option: any) => {
            const [filter] = params?.filter(
              (p: any) => p?.title === option?.title
            );
            return (
              <a
                onClick={() => handleFilters(option)}
                className={`inline-flex mx-1 rounded-full px-2 py-1 ${
                  filter?.active ? "bg-white shadow-sm" : ""
                }`}
              >
                {option?.title}
              </a>
            );
          })}
        </div>
        <button
          className="w-full lg:w-24 shadow-sm bg-white rounded py-2.5 px-4 border border-gray-300 text-sm flex justify-center lg:justify-start items-center outline-none hover:bg-gray-50 focus:outline-none foucs:border-0 focus:ring focus:ring-gray-200"
          onClick={() => refreshOrders()}
        >
          <RefreshIcon className="w-3 h-3 ml-1.5" />
          ریفرش
        </button>
      </div>
      <Input
        placeholder="جستجو"
        className="mb-3 text-sm shadow-sm h-10"
        onPressEnter={handleSearch}
      />
      <Spin tip="در حال دریافت اطلاعات..." spinning={loading}>
        <Table
          columns={Model({ retryOrder, manualFullfill, manualRefund })}
          dataSource={data}
          pagination={{ ...pagination, onChange: handlePagination }}
          className="text-right"
          rowKey="id"
        />
      </Spin>
      <Switch>
        <Route path={`${props.match.url}/:orderId/bot`}>
          <OrderBotLog {...props} />
        </Route>

        <Route path={`${props.match.url}/:orderId`}>
          <OrderAttachments {...props} />
        </Route>
      </Switch>
    </div>
  );
};
