import { Form, Modal, Spin, Table, Input, Radio, Button, message } from "antd";
import { Model } from "./Model";
import React, { useEffect, useState } from "react";
import { PlusIcon, RefreshIcon } from "@heroicons/react/outline";
import { Route } from "react-router-dom";
import { Card } from "../../services/Api/Card";
import { Link } from "react-router-relative-link-5";

export const Cards = (props: any) => {
  const [cards, setCards] = useState();
  const [loading, setLoading] = useState(false);

  const addCard = async (values: {
    cardNumber: string;
    currencyCode: "string";
  }) => {
    try {
      const { data }: any = await Card.add(values).json();
      if (!data) {
        return;
      }
      message.success("کارت با موفقیت اضافه شد");
      getCards();
    } catch (e) {
      console.error(e);
    }
  };

  const getCards = async () => {
    const { data }: any = await Card.getAll().json();
    if (!data) {
      return;
    }
    setCards(data);
  };

  const updateCardBalance = async (id: string) => {
    message.info("در حال آپدیت کارت ..");
    const { data }: any = await Card.updateBalance(id).json();
    if (!data) {
      return;
    }
    message.success("کارت با موفقیت آپدیت شد");
    getCards();
  };

  useEffect(() => {
    (async () => {
      try {
        getCards();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div>
      <h1 className="text-xl font-bold mb-3">کارت ها</h1>
      <p className="text-muted pt-half text-gray-500 mb-5">
        کارت هایی که سابسکریپشن ها با آن خریداری می‌شوند را از اینجا مدیریت
        کنید.
      </p>
      <div className="border-t border-gray-100 -mx-10 mb-5" />
      <div className="flex">
        <Link to="add">
          <button className="shadow-sm bg-white rounded py-2.5 px-4 border border-gray-300 text-sm flex items-center mb-5">
            <PlusIcon className="w-3 h-3 ml-1.5" />
            افزودن کارت
          </button>
        </Link>
      </div>
      <Spin tip="در حال دریافت اطلاعات..." spinning={loading}>
        <Table
          columns={Model({ updateCardBalance })}
          dataSource={cards}
          className="text-right"
          rowKey="id"
        />
        <Route path={`${props.match.url}/add`}>
          <Modal
            visible={true}
            footer={false}
            onCancel={() => props.history.push("/manage-cards")}
          >
            <h2 className="text-lg font-bold mb-5">افزودن کارت</h2>
            <Form onFinish={addCard}>
              <Form.Item label="شماره کارت" className="mb-3" name="cardNumber">
                <Input
                  className="mt-1 text-left"
                  style={{ direction: "ltr" }}
                />
              </Form.Item>
              <Form.Item label="کد ارزی" className="mb-6" name="currencyCode">
                <Radio.Group value="TL" className="mt-1">
                  <Radio.Button value="TL">TL</Radio.Button>
                  <Radio.Button value="USD">USD</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Button
                type="primary"
                className="rounded py-1 px-5 h-10 shadow-sm"
                htmlType="submit"
              >
                ذخیره
              </Button>
            </Form>
          </Modal>
        </Route>
      </Spin>
    </div>
  );
};
