import React, { useEffect, useState } from "react";
import { Order } from "../../services/Api/Order";
import { Bot } from "../../services/Api/Bot";
import { Button, message, Modal, Switch } from "antd";
import { Route } from "react-router-dom";
import { EditBotForm } from "./components/EditBotForm";
import { Link } from "react-router-relative-link-5";
import { AddBotForm } from "./components/AddBotForm";
import { TerminalIcon } from "@heroicons/react/solid";
import { BotLog } from "./components/BotLog";

export const Bots = (props: any) => {
  const [data, setData] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [addBot, setAddBot] = useState<boolean>(false);
  const [automationEnabled, setAutomationStatus] = useState<boolean>();
  const [pagination, setPagination] = useState<{
    current: number;
    total: number;
  }>();

  const refreshBots = async (forceGetPagination?: string) => {
    try {
      setLoading(true);
      const { data, pagination: resPagination }: any = await Bot.getAll({
        page: pagination?.current || 1,
      }).json();
      if (!data) {
        return;
      }
      setData(data);
      if (
        pagination?.current !== +resPagination.current ||
        forceGetPagination
      ) {
        setPagination({
          current: +resPagination?.current,
          total: resPagination?.total * 10,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await refreshBots();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [pagination]);

  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await Order.getProcessStatus().json();
        if (!data) {
          return;
        }
        const { isActive } = data;
        if (!isActive) {
          return;
        }
        setAutomationStatus(isActive);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleAutomation = async (status: boolean) => {
    try {
      const { data }: any = await Order.process(status).json();
      if (!data) {
        return;
      }
      setAutomationStatus(status);
      message.success(`با موفقیت ${!status ? "غیرفعال" : "فعال"} شد.`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <h1 className="text-xl font-bold mb-3">بات ها</h1>
      <p className="text-muted pt-half text-gray-500 mb-8">
        بات های جدید را از این صفحه اضافه کنید و بات های سیستم را مدیریت کنید.
      </p>
      <div className="border-t border-gray-100 -mx-10 mb-5" />
      <div className="flex items-center justify-between">
        <div>
          پردازش اتوماتیک
          <Switch
            className="mr-3"
            onChange={handleAutomation}
            checked={automationEnabled}
          />
        </div>
        <Button
          className="rounded py-1 px-5 h-10 shadow-sm border border-gray-300 hover:text-purple-700"
          onClick={() => setAddBot(true)}
        >
          افزودن بات
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 mt-4 gap-4">
        {data &&
          data.map(
            ({ id, isActive, paymentMethod, proxyUri, lastDigits, name }) => (
              <div
                className="shadow-sm border rounded-md pt-5 relative overflow-hidden"
                key={id}
              >
                <div className="px-4">
                  <div className="mb-3">
                    <span className="font-medium pl-3">نام:</span>
                    {name}
                  </div>
                  <div className="mb-3">
                    <span className="font-medium pl-3">روش پرداخت:</span>
                    {paymentMethod}
                  </div>
                  <div className="mb-3">
                    <span className="font-medium pl-3">پراکسی:</span>
                    {proxyUri || "وارد نشده"}
                  </div>
                  <div className="mb-5">
                    <span className="font-medium pl-3">کارت:</span>
                    <span
                      className="text-left inline-flex"
                      style={{ direction: "ltr" }}
                    >
                      {lastDigits
                        ? `****-****-****-${lastDigits}`
                        : "وارد نشده"}
                    </span>
                  </div>
                  <div className="mb-4">
                    <span className="rounded-md bg-gray-50 py-1 px-2">
                      {id}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex items-center justify-between">
                  <div className="status ml-4">
                    {isActive ? (
                      <span className="flex items-center">
                        <div className="w-2 h-2 bg-green-300 rounded-full ml-2" />
                        فعال
                      </span>
                    ) : (
                      <span className="flex items-center">
                        <div className="w-2 h-2 bg-gray-300 rounded-full ml-2" />
                        غیر فعال
                      </span>
                    )}
                  </div>
                  <div className="flex">
                    <Link to={id} className="flex">
                      <Button
                        className="rounded py-1 px-3 h-10 shadow-sm border border-gray-300 hover:text-purple-700"
                        htmlType="submit"
                      >
                        ویرایش
                      </Button>
                    </Link>
                    <Link to={`${id}/log`}>
                      <Button className="rounded flex items-center mr-2 justify-center py-1 px-3 h-10 shadow-sm border border-gray-300 hover:text-purple-700">
                        <TerminalIcon className="w-4 h-4 gray-200" />
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )
          )}
      </div>
      {addBot && <AddBotForm addBot={addBot} setAddBot={setAddBot} />}
      <Route path={`${props.match.url}/:botId/log`}>
        <BotLog {...props} />
      </Route>
      <Route path={`${props.match.url}/:botId`} exact>
        <EditBotForm {...props} refreshBots={refreshBots} />
      </Route>
    </div>
  );
};
