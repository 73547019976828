import React from "react";
import { useAuth } from "./hooks/useAuth";
import App from "./App";
import { Login } from "./Login";

export const AuthHandler = () => {
  const { user } = useAuth();
  console.log(user);

  return user ? <App /> : <Login />;
};
