import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Order } from "../../services/Api/Order";
import { Modal, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import TimeAgo from "timeago-react";
const token = localStorage.getItem("dono-secret") || "";

const LogModel = [
  {
    title: "بات",
    dataIndex: "botId",
    key: "botId",
    render: (botId: string, row: any) => (
      <span>
        <a className="text-underline" href={`/bots/${botId}`}>
          {botId}
        </a>{" "}
        <br /> <Tag>{row?.bot?.name}</Tag>
        <Tag>{row?.bot?.paymentMethod}</Tag>
      </span>
    ),
  },
  {
    title: "عملیات",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "تاریخ",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: Date) => (
      <Tooltip
        title={moment(createdAt).format("L - LT")}
        className="cursor-pointer"
      >
        <TimeAgo
          datetime={createdAt}
          locale="fa"
          className="dono-dock-time px-1 mb-0"
        />
      </Tooltip>
    ),
  },
];

export const OrderBotLog = (props: any) => {
  const { orderId } = useParams<any>();
  const [logs, setLogs] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        if (!orderId) {
          return;
        }

        const { data }: any = await Order.getLogsById({
          id: orderId,
          page: 1,
          limit: 100,
        }).json();
        if (!data) {
          return;
        }
        setLogs(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [orderId]);

  return (
    <Modal
      visible={true}
      onCancel={() => props.history.push("/orders")}
      footer={false}
      width={700}
    >
      <Table dataSource={logs} columns={LogModel}></Table>
    </Modal>
  );
};
