import React, { useState, useEffect, useContext, createContext } from "react";
const authContext = createContext<any>(null);

export function ProvideAuth({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<any>(null);
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.

  useEffect(() => {
    const savedPassword = localStorage.getItem("dono-secret");
    if (!savedPassword) {
      return;
    }
    setUser(savedPassword);
  }, []);

  const saveUser = (password: string) => {
    localStorage.setItem("dono-secret", password);
    setUser(password);
  };

  return {
    user,
    saveUser,
  };
}
