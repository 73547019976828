import React, {
  createContext,
  EventHandler,
  useContext,
  useState,
} from "react";
import { Switch as _Switch, Slider as _Slider } from "antd";

const HandlerContext = React.createContext<any>(null);

export const SubscriptionFields = ({
  handler,
  children,
  id,
}: {
  handler: (id: string, payload: { [p: string]: string }) => void;
  children: JSX.Element | JSX.Element[];
  id: string;
}) => {
  return (
    <HandlerContext.Provider value={{ handler, id }}>
      {children}
    </HandlerContext.Provider>
  );
};

const Input = ({
  patchPayload,
  label,
  ...props
}: {
  patchPayload: { [patchKey: string]: any };
  label: string;
  [prop: string]: any;
}) => {
  const [patchKey] = Object.keys(patchPayload);
  const patchValue = patchPayload[patchKey];
  const [value, setValue] = useState(patchValue);
  const [to, setTo] = useState<any>();
  const { handler: finalHandler, id } = useContext(HandlerContext);

  const handleUserType = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(to);
    const { value } = e?.target;
    setValue(value);
    const finalHandlerCall = setTimeout(
      () => finalHandler(id, { [patchKey]: +value }),
      1000
    );
    setTo(finalHandlerCall);
  };

  return (
    <div className="flex items-center" {...props}>
      <span className="flex-none mb-1.5 flex" style={{ fontSize: "14px" }}>
        {label}
      </span>
      <div className="flex-auto w-full">
        <input
          className="w-full border rounded-md h-8 outline-none px-3 py-2"
          type="text"
          value={value}
          onChange={handleUserType}
        />
      </div>
    </div>
  );
};

const Switch = ({
  patchPayload,
  label,
  ...props
}: {
  patchPayload: { [patchKey: string]: any };
  label: string;
  [prop: string]: any;
}) => {
  const [patchKey] = Object.keys(patchPayload);
  const patchValue = patchPayload[patchKey];
  const { handler: finalHandler, id } = useContext(HandlerContext);

  const handleSwitchChange = (value: boolean) => {
    finalHandler(id, { [patchKey]: value });
  };

  return (
    <div className="flex">
      <label className="pl-2">{label}</label>
      <_Switch defaultChecked={patchValue} onChange={handleSwitchChange} />
    </div>
  );
};

const Slider = ({
  patchPayload,
  label,
  ...props
}: {
  patchPayload: { [patchKey: string]: any };
  label: string;
  [prop: string]: any;
}) => {
  const [patchKey] = Object.keys(patchPayload);
  const patchValue: { min: number; max: number } = patchPayload[patchKey] || [
    1,
    100,
  ];
  const { handler: finalHandler, id } = useContext(HandlerContext);

  const handleSliderChange = (value: number[]) => {
    const [min, max] = value;
    finalHandler(id, { [patchKey]: { min, max } });
  };

  return (
    <div className="flex flex-col">
      <label className="pl-2">{label}</label>
      <_Slider
        range
        defaultValue={[patchValue.min, patchValue.max]}
        min={1}
        max={100}
        onAfterChange={handleSliderChange}
      />
    </div>
  );
};

SubscriptionFields.Input = Input;
SubscriptionFields.Switch = Switch;
SubscriptionFields.Slider = Slider;
