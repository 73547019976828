import React from "react";
import moment from "moment";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
import { Dropdown, Menu, Popconfirm, Tooltip } from "antd";
import {
  DotsHorizontalIcon,
  ExternalLinkIcon,
  PaperClipIcon,
  ExclamationCircleIcon,
  RefreshIcon,
  TerminalIcon,
  ChipIcon,
  ChatAltIcon
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
timeago.register("fa", fa);

export const Model = ({
  retryOrder,
  manualFullfill,
  manualRefund,
}: {
  retryOrder: (id: string) => void;
  manualFullfill: (id: string) => void;
  manualRefund: (id: string) => void;
}) => [
  {
    title: "شناسه",
    dataIndex: "prettyId",
    key: "prettyId",
    width: "10%",
    render: (prettyId: string, row: any) => (
      <Tooltip title={row?.id}>
        <span>{prettyId}</span>
      </Tooltip>
    ),
  },
  {
    title: "استریمر",
    dataIndex: "streamerName",
    key: "streamerName",
    render: (streamerName: string, row: any) => (
      <a
        href={`https://twitch.tv/${streamerName}`}
        className="flex text-purple-600 capitalize py-1.5"
        target="_blank"
      >
        {streamerName}
        <ExternalLinkIcon className="w-4 h-4 relative mr-0.5 top-0.5" />
      </a>
    ),
    width: "16%",
  },
  {
    title: "خریدار",
    dataIndex: "targetName",
    key: "targetName",
    width: "16%",
    render: (targetName: string, row: any) => (
      <div>
        {targetName}
        {
          row?.subscriptionMessage &&
          <Tooltip title={row?.subscriptionMessage}>
            <ChatAltIcon className="w-4 h-4 relative mr-0.5 top-0.5" />
          </Tooltip>
        }
      </div>
    )
  },
  {
    title: "وضعیت",
    dataIndex: "status",
    key: "status",
    render: (status: string, row: any) => {
      return (
        <div className="flex">
          {status === "PAID" || status === "PENDING" ? (
            <span className="bg-blue-50 rounded py-1 px-2 text-blue-500 uppercase text-xs font-weight-medium">
              پرداخت شده
            </span>
          ) : status === "COMPLETED" ? (
            <Tooltip title={row.botId}>
              <Link to={`../bots/${row.botId}`}>
                <span className="bg-green-50 rounded py-1 px-2 text-green-500 uppercase text-xs font-weight-medium cursor-pointer">
                  موفق
                </span>
              </Link>
            </Tooltip>
          ) : status === "MANUALLY_FULFILLED" ? (
            <Tooltip title={row.botId}>
              <Link to={`../bots/${row.botId}`}>
                <span className="bg-green-50 rounded py-1 px-2 text-green-500 uppercase text-xs font-weight-medium">
                  دستی
                </span>
              </Link>
            </Tooltip>
          ) : status === "CREATED" ? (
            <span className="bg-gray-50 rounded py-1 px-2 text-gray-500 uppercase text-xs font-weight-medium">
              جدید
            </span>
          ) : status === "FAILED" ? (
            <Tooltip title={row.botId}>
              <Link to={`../bots/${row.botId}`}>
                <span className="bg-red-50 rounded py-1 px-2 text-red-500 uppercase text-xs font-weight-medium">
                  ناموفق
                </span>
              </Link>
            </Tooltip>
          ) : status === "CANCELED" ? (
            <span className="bg-yellow-50 rounded py-1 px-2 text-yellow-500 uppercase text-xs font-weight-medium">
              کنسل
            </span>
          ) : status === "REFUNDED" ? (
            <span className="bg-yellow-50 rounded py-1 px-2 text-yellow-500 uppercase text-xs font-weight-medium">
              ریفاند
            </span>
          ) : status === "AWAITING_COMPLETED" ? (
            <span className="bg-yellow-50 rounded py-1 px-2 text-yellow-500 uppercase text-xs font-weight-medium">
              اغما
            </span>
          ) : (
            <>{status}</>
          )}
          <Tooltip title={row?.failReason} className="mx-1">
            {row?.failReason ? (
              <ExclamationCircleIcon className="w-5 h-5 text-gray-300 cursor-pointer" />
            ) : (
              <></>
            )}
          </Tooltip>
        </div>
      );
    },
    width: "11%",
  },
  {
    title: "گیفت",
    dataIndex: "isCommunityGift",
    key: "isCommunityGift",
    render: (isCommunityGift: boolean | string, row: any) => (
      <>
        {isCommunityGift === "true" || isCommunityGift
          ? row?.communityGiftQuantity
          : "-"}
      </>
    ),
  },
  {
    title: "Tier",
    dataIndex: "tier",
    key: "tier",
    render: (
      tier: any,
      { subscription }: { subscription?: { tier?: number } }
    ) => (
      <span
        className={`px-1.5 rounded-md ${
          subscription?.tier === 3
            ? "bg-yellow-50 text-yellow-500"
            : subscription?.tier === 2
            ? "bg-indigo-50 text-indigo-500"
            : ""
        }`}
      >
        {subscription?.tier}
      </span>
    ),
  },

  {
    title: "تاریخ",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt: Date) => (
      <Tooltip
        title={moment(createdAt).format("L - LT")}
        className="cursor-pointer"
      >
        <TimeAgo
          datetime={createdAt}
          locale="fa"
          className="dono-dock-time px-1 mb-0"
        />
      </Tooltip>
    ),
  },

  {
    title: "",
    render: (actions: any, row: any) => (
      <div className="flex justify-end items-center pl-4">
        {(row?.status === "FAILED" || row?.status === "PAID") && (
          <Popconfirm
            title="آیا مطمئن هستید؟"
            onConfirm={() => retryOrder(row?.id)}
          >
            <button className="shadow-sm bg-white rounded py-2 px-3 ml-6 border border-gray-300 text-xs flex items-center">
              <RefreshIcon className="w-3 h-3 ml-1.5" />
              مجدد
            </button>
          </Popconfirm>
        )}
        {row?.status !== "CREATED" && (
          <Link to={`orders/${row?.id}`}>
            <PaperClipIcon className="w-6 h-6 text-gray-400 cursor-pointer ml-4" />
          </Link>
        )}
        <Link to={`orders/${row?.id}/bot`}>
          <ChipIcon className="w-6 h-6 text-gray-400 cursor-pointer ml-4" />
        </Link>
        <Dropdown
          overlay={
            <Menu style={{ minWidth: 120 }}>
              <Menu.Item>
                <a onClick={() => manualFullfill(row?.id)}>تکمیل دستی</a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => manualRefund(row?.id)}>ریفاند</a>
              </Menu.Item>
              <Menu.Item>
                <a href={`orders/${row?.id}/bot`}>فعالیت بات</a>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <DotsHorizontalIcon className="w-6 h-6 text-gray-400 cursor-pointer" />
        </Dropdown>
      </div>
    ),
  },
];
