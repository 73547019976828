import React, { useEffect, useState } from "react";
import "antd/dist/antd.less";
import "./scss/app.scss";
import "./scss/tailwind.scss";
import { ConfigProvider, Drawer } from "antd";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Orders } from "./pages/Orders/Orders";

import {
  AdjustmentsIcon,
  ArrowLeftIcon,
  ChipIcon,
  CollectionIcon,
  CreditCardIcon,
  MenuAlt2Icon,
  ShoppingCartIcon,
  CalculatorIcon,
} from "@heroicons/react/outline";
import { Auth } from "./services/Api/Auth";
import { Cards } from "./pages/Cards/Cards";
import { Transactions } from "./pages/Transactions/Transactions";
import { Settings } from "./pages/Settings/Settings";
import { Sidebar } from "./layout/Sidebar";
import { Bots } from "./pages/Bots/Bots";

function App() {
  const { pathname } = window?.location;
  const pathNameWithoutSlash = pathname?.substr(1);
  const [tab, setTab] = useState(
    !pathNameWithoutSlash ? "orders" : pathNameWithoutSlash
  );
  const [mobileNav, setMobileNav] = useState<boolean>(false);

  const iconClass = "w-5 h-5";

  const navTabs = [
    {
      id: "orders",
      title: "سفارش ها",
      icon: <ShoppingCartIcon className={iconClass} />,
    },
    {
      id: "transactions",
      title: "تراکنش ها",
      icon: <CreditCardIcon className={iconClass} />,
    },
    {
      id: "bots",
      title: "بات ها",
      icon: <ChipIcon className={iconClass} />,
    },
    {
      id: "manage-cards",
      title: "مدیریت کارت ها",
      icon: <CollectionIcon className={iconClass} />,
    },
    {
      id: "settings",
      title: "تنظیمات",
      icon: <AdjustmentsIcon className={iconClass} />,
    },
  ];

  return (
    <Router>
      <ConfigProvider direction="rtl">
        <div className="layout flex">
          <Sidebar tabs={navTabs} selectedTab={tab} setSelectedTab={setTab} />
          <div className="App flex-auto lg:pr-68 pb-20">
            <div className="app-cover"></div>
            <div className="px-6 lg:px-14" style={{ maxWidth: "100vw" }}>
              <div className="container mx-auto" style={{ maxWidth: 1250 }}>
                <ArrowLeftIcon
                  className="w-5 h-5 text-white cursor-pointer lg:hidden absolute top-6"
                  onClick={() => setMobileNav(true)}
                />

                <div
                  className="app-block relative px-10 pt-6 overflow-hidden mb-12"
                  style={{ top: 72 }}
                >
                  <div className="app-main-content px-10 pb-7 -mx-10 pt-2 bg-white">
                    <Drawer
                      visible={mobileNav}
                      onClose={() => setMobileNav(false)}
                      closeIcon={
                        <MenuAlt2Icon className="w-5 h-5 text-gray-700" />
                      }
                    >
                      <Sidebar
                        tabs={navTabs}
                        selectedTab={tab}
                        setSelectedTab={setTab}
                        mobileMode={mobileNav}
                      />
                    </Drawer>
                    <Switch>
                      <Route path="/" exact component={Orders} />
                      <Route path="/orders" component={Orders} />
                      <Route path="/bots" component={Bots} />
                      <Route path="/manage-cards" component={Cards} />
                      <Route path="/transactions" component={Transactions} />
                      <Route path="/settings" component={Settings} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </Router>
  );
}

export default App;
