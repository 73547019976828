import t1 from "../../../t1.svg";
import t2 from "../../../t2.svg";
import t3 from "../../../t3.svg";
import t3Drop from "../../../t3-drop.svg";
import React from "react";

export const TierIcon = ({
  tier,
  append,
}: {
  tier: number;
  append?: JSX.Element | JSX.Element[];
}) => {
  return (
    <div className="flex flex-col ml-6">
      <div
        className={`tier-icon rounded-md flex flex-none items-center justify-center w-12 h-12 ${
          tier === 1
            ? "bg-gray-100"
            : tier === 2
            ? "bg-yellow-100"
            : tier === 3
            ? "bg-purple-100"
            : "bg-gray-50"
        }`}
      >
        {tier === 1 && <img src={t1} />}
        {tier === 2 && <img src={t2} className="animate animate-bounce" />}
        {tier === 3 && (
          <>
            <img src={t3} />
            <img src={t3Drop} className="absolute -mr-3 animate-ping" />
          </>
        )}
      </div>
      {append}
    </div>
  );
};
