import React, { useEffect, useState } from "react";
import { Subscription } from "../../../services/Api/Subscription";
import t1 from "../../../t1.svg";
import t2 from "../../../t2.svg";
import t3 from "../../../t3.svg";
import t3Drop from "../../../t3-drop.svg";
import { SubscriptionFields } from "./SubscriptionFields";
import { TierIcon } from "./TierIcon";
import { message, Switch } from "antd";
import TimeAgo from "timeago-react";

export const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<any[]>();

  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await Subscription.getAll().json();
        if (!data) {
          return;
        }
        setSubscriptions(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const patchHandler = async (id: string, payload: any) => {
    try {
      message.info("در حال ذخیره..");
      const { data }: any = await Subscription.edit({ id, payload }).json();
      if (!data) {
        return;
      }
      message.success("با موفقیت انجام شد.");
    } catch (e) {
      message.error("خطا در آپدیت اطلاعات");
    }
  };

  return (
    <div className="mt-3">
      <div className="subscriptions grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
        {subscriptions &&
          subscriptions
            ?.sort((a, b) => (a.tier > b.tier ? 1 : -1))
            .map(
              ({
                id,
                tier,
                staticPrice,
                percentPrice,
                currencyAmount,
                priceCurrency,
                updatedAt,
                isActive,
                tomanPrice,
                isCommunityGiftActive,
                communityGiftRange,
                maxAllowedPrice,
              }: {
                tier: number;
                id: string;
                staticPrice: number;
                percentPrice: number;
                currencyAmount: number;
                priceCurrency: string;
                updatedAt: Date;
                isActive: boolean;
                tomanPrice: number;
                isCommunityGiftActive: boolean;
                maxAllowedPrice: number;
                communityGiftRange: {
                  min: number;
                  max: number;
                };
              }) => (
                <div
                  className="subscription-plan flex border rounded-lg px-7 py-5"
                  key={id}
                >
                  <TierIcon
                    tier={tier}
                    append={
                      <div className="mt-4">
                        <Switch
                          defaultChecked={isActive}
                          onChange={(e) =>
                            patchHandler(id, { isActive: !isActive })
                          }
                        />
                      </div>
                    }
                  />
                  <div className="flex flex-col w-full">
                    <span className="text-base font-medium flex-auto mb-4 mt-2">
                      سابسکریپشن Tier {tier}
                    </span>
                    <SubscriptionFields handler={patchHandler} id={id}>
                      <div className="flex flex-col">
                        <div className="grid grid-cols-2 gap-4">
                          <SubscriptionFields.Input
                            patchPayload={{ staticPrice }}
                            label="سود ثابت"
                            className="mb-3"
                          />
                          <SubscriptionFields.Input
                            patchPayload={{ percentPrice }}
                            label="درصد مبلغ"
                            className="mb-3"
                          />
                        </div>
                        <div className="flex items-end mb-4 relative">
                          <SubscriptionFields.Input
                            patchPayload={{ currencyAmount }}
                            label="قیمت اصلی"
                            className="flex-1"
                          />
                          <span className="h-8 w-10 rounded-l-md text-xs flex items-center justify-center bg-gray-50 flex-none absolute left-0 border border-gray-200">
                            {priceCurrency}
                          </span>
                        </div>
                        <div className="flex items-end mb-4 relative">
                          <SubscriptionFields.Input
                            patchPayload={{ maxAllowedPrice }}
                            label="حداکثر قیمت مجاز"
                            className="flex-1"
                          />
                          <span className="h-8 w-10 rounded-l-md text-xs flex items-center justify-center bg-gray-50 flex-none absolute left-0 border border-gray-200">
                            {priceCurrency}
                          </span>
                        </div>
                        <div className="flex mb-4">
                          <SubscriptionFields.Switch
                            label="قابلیت گیفت"
                            patchPayload={{ isCommunityGiftActive }}
                          />
                        </div>
                        <div className="mb-4">
                          <SubscriptionFields.Slider
                            label="محدوده ساب‌گیفت"
                            patchPayload={{ communityGiftRange }}
                          />
                        </div>
                        <span className="block mb-2">
                          قیمت نهایی: {tomanPrice?.toLocaleString()} تومان
                        </span>
                        <span className="text-xs text-gray-500">
                          آخرین تغییر:
                          <TimeAgo
                            datetime={updatedAt}
                            locale="fa"
                            className="dono-dock-time px-1 mb-0"
                          />
                        </span>
                      </div>
                    </SubscriptionFields>
                  </div>
                </div>
              )
            )}
      </div>
    </div>
  );
};
