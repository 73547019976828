import ky from "ky";

const savedPassword = localStorage.getItem("dono-secret") || "";

const { get, post, patch, put, delete: destroy } = ky.create({
  prefixUrl: "https://sub-api.dono.gg/v2/",
  headers: {
    secret: savedPassword,
  },
});


export { get, post, patch, put, destroy };
