import React, { useState } from "react";
import { Auth } from "./services/Api/Auth";
import { Button, message } from "antd";
import login from "./login.png";
import { useAuth } from "./hooks/useAuth";

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [secret, setSecret] = useState<string>();
  const { saveUser } = useAuth();

  const loginReq = async (e: React.ChangeEvent<any>) => {
    try {
      e.preventDefault();
      if (typeof secret === "undefined") {
        return;
      }
      const { data }: any = await Auth.login({
        secret,
      }).json();
      if (!data) {
        return;
      }
      message.success("با موفقیت وارد شدید.");
      saveUser(secret);

      setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
      console.error(e);
      message.error("مشکلی پیش آمد.");
    }
  };

  return (
    <div className="App flex-auto">
      <div className="app-cover" style={{ width: "100%" }}></div>
      <div className="px-6 lg:px-14">
        <div className="container mx-auto" style={{ maxWidth: 400 }}>
          <div
            className="app-block relative px-10 pt-6 mb-12"
            style={{ top: 120 }}
          >
            <div className="app-main-content px-10 pb-7 -mx-10 pt-2 bg-white flex flex-col justify-center">
              <div className="flex items-center justify-center mb-4">
                <img src={login} className="h-36 -mt-24 -ml-10" />
              </div>
              <h1 className="font-bold text-lg mb-4 text-center">
                ورود به پنل
              </h1>
              <span className="text-gray-500 mb-3 block">
                برای ورود لازم است تا رمز عبور خود را وارد کنید.
              </span>
              <form name="login" id="login">
                <div className="w-full">
                  <input
                    className="w-full border rounded-md h-10 outline-none px-3 py-2 mb-4"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="رمز عبور"
                    value={secret}
                    onChange={(e) => setSecret(e?.target?.value)}
                  />
                </div>
                <Button
                  type="primary"
                  block
                  className="rounded py-1 px-5 h-10 shadow-sm"
                  onClick={loginReq}
                  htmlType="submit"
                  loading={loading}
                >
                  ورود
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
