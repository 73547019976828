import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { Bot } from "../../../services/Api/Bot";
import moment from "moment";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";

export const BotLog = (props: any) => {
  const { botId } = useParams<any>();
  const [botLog, setBotLog] = useState<any[]>();
  const [toId, setToId] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const getBotLogs = async (botId: string) => {
    try {
      setLoading(true);
      const { data }: any = await Bot.getLogsById({ id: botId }).json();
      if (!data) return;
      setBotLog(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!botId) return;

        getBotLogs(botId);
        const to: any = setInterval(() => getBotLogs(botId), 5000);
        setToId(to);

        return () => {
          clearInterval(to);
        };
      } catch (e) {
        console.error(e);
      }
    })();
  }, [botId]);

  const handleCloseModal = () => {
    props.history.push("/bots");
    if (!toId) {
      return;
    }
    clearInterval(toId);
  };

  return (
    <Modal
      visible={true}
      onCancel={handleCloseModal}
      footer={false}
      width={700}
      className="console-mode"
    >
      <div className="custom-scroll w-full max-h-80 overflow-auto text-left">
        <>
          {loading ? "Getting Logs.." : "Auto Refresh ON"}{" "}
          <span className="font-bold animate-pulse"> |</span> <br />
          <br />
          {botLog?.map(({ id, botId, orderId, action, createdAt }: any) => (
            <div className="mb-4">
              Order {id}
              <br />
              <span className="text-green-500 font-bold">{action}</span> <br />
              <>
                -
                <TimeAgo datetime={createdAt} />
              </>
            </div>
          ))}
        </>
      </div>
    </Modal>
  );
};
