import { get, patch, post } from "../../config/api";

export const Order = {
  getAll: ({ page = 1, limit = 10, params = "", query = "" }) =>
    get(
      `orders?page=${page}&limit=${limit}${params && `&${params}`}${
        query && `&query=${query}`
      }`
    ),
  getById: (id: string) => get(`orders/${id}`),
  getLogsById: ({
    page = 1,
    limit = 10,
    id,
  }: {
    page: number;
    limit: number;
    id: number;
  }) => get(`orders/${id}/logs?page=${page}&limit=${limit}`),
  fullfill: (id: string) => post(`orders/${id}/fulfill`),
  retry: (id: string) => post(`orders/${id}/retry`),
  edit: ({
    id,
    payload,
  }: {
    id: string;
    payload: { status: "MANUALLY_FULFILLED" | "REFUNDED" };
  }) => patch(`orders/${id}`, { json: { ...payload } }),
  process: (status: boolean) =>
    post(`orders/process`, { json: { isActive: status } }),
  getProcessStatus: () => get(`orders/process`),
};
