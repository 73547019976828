import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Order } from "../../services/Api/Order";
import { Modal, Tabs } from "antd";

const { TabPane } = Tabs;
const token = localStorage.getItem("dono-secret") || "";

export const OrderAttachments = (props: any) => {
  const { orderId } = useParams<any>();
  const [order, setOrder] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        if (!orderId) {
          return;
        }

        const { data }: any = await Order.getById(orderId).json();
        if (!data) {
          return;
        }
        setOrder(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [orderId]);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  return (
    <Modal
      visible={true}
      onCancel={() => props.history.push("/orders")}
      footer={false}
      width={width < 500 ? "calc(100vw - 50px)" : "700px"}
    >
      {order && order?.browserImages && (
        <>
          <Tabs defaultActiveKey="1">
            <TabPane tab="تصویر" key="1">
              {order?.browserImages.map((image: any) => (
                <img src={`${image?.name}?secret=${token}`} />
              ))}
            </TabPane>
            <TabPane tab="ویدیو" key="2">
              {order?.browserVideos.map((video: any, index: number) => (
                <video
                  width="100%"
                  height="240"
                  controls
                  className={`${
                    index === order?.browserVideos?.length - 1 ? "" : "mb-4"
                  }`}
                >
                  <source
                    src={`${video?.name}?secret=${token}`}
                    type="video/mp4"
                  />
                  مرورگر شما از قابلیت ویدیو پشتیبانی نمی‌کند.
                </video>
              ))}
            </TabPane>
          </Tabs>
        </>
      )}
    </Modal>
  );
};
