import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { Model } from "./Model";
import { Transaction } from "../../services/Api/Transaction";

export const Transactions = () => {
  const [transactions, setTransactions] = useState();
  const [loading, setLoading] = useState(false);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const { data }: any = await Transaction.getAll().json();
      if (!data) {
        return;
      }
      setTransactions(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        getTransactions();
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div>
      <h1 className="text-xl font-bold mb-3">تراکنش ها</h1>
      <p className="text-muted pt-half text-gray-500 mb-8">
        آخرین تراکنش های انجام شده توسط دونو در زیر لیست شده است.
      </p>
      <Spin tip="در حال دریافت اطلاعات..." spinning={loading}>
        <Table
          columns={Model}
          dataSource={transactions}
          className="text-right"
          rowKey="id"
        />
      </Spin>
    </div>
  );
};
