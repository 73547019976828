import { ArrowLeftIcon, MenuAlt2Icon } from "@heroicons/react/solid";
import avatar2 from "../avatar3.png";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ExchangeRate } from "../services/Api/ExchangeRate";

export const Sidebar = ({
  tabs: navTabs,
  selectedTab,
  setSelectedTab,
  mobileMode,
}: {
  tabs: any[];
  selectedTab: string;
  setSelectedTab: (id: string) => void;
  mobileMode?: boolean;
}) => {
  const [exchangeRate, setExchangeRate] = useState<any>();
  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await ExchangeRate.getTomanPrice().json();
        if (!data) return;
        setExchangeRate(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div
      className={`sidebar ${
        mobileMode
          ? "w-68"
          : "hidden lg:block w-68 bg-white fixed h-full shadow-sm px-7 py-7 transition"
      }`}
    >
      <div className="flex flex-col items-between justify-between h-full">
        <div>
          <div className="top-menu flex justify-between items-center mb-8">
            <div className="app-logo-container flex items-center ">
              <img
                src={require("../logo.svg")}
                width={28}
                className="ml-3 relative -top-1"
              />
              <span className="app-caption">دونو</span>
            </div>
            <div className="collapse">
              {!mobileMode && (
                <MenuAlt2Icon className="w-5 h-5 text-gray-700" />
              )}
            </div>
          </div>
          <div className="admin-avatar flex flex-col items-center justify-center">
            <img src={avatar2} className="w-24 h-24 rounded-full mw-auto" />
            <span className="bg-yellow-300 rounded py-1 px-2 relative -top-4">
              پنل مدیریت
            </span>
          </div>
          <div className="nav border-t mt-3 pt-7 border-gray-100 px-1">
            <nav className="app-nav">
              <div className="flex-none">
                <ul className="nav pr-0 flex flex-col">
                  {navTabs.map(({ id, icon, title }, i) => (
                    <li key={i} className="nav-item mb-1">
                      <Link
                        to={`/${id}`}
                        className={`nav-link flex items-center rounded py-3 px-3 text-gray-700 ${
                          selectedTab === id
                            ? "bg-purple-custom text-purple-500 font-medium border-purple-300"
                            : ""
                        }`}
                        onClick={() => setSelectedTab(id)}
                      >
                        <div className="pl-3">{icon}</div> {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="flex items-center justify-center">
          {exchangeRate && exchangeRate?.amount && (
            <>
              <div className="flex items-center bg-gray-50 py-1 px-3 font-bold text-gray-600 mr-1 rounded-full cursor-pointer ml-2">
                TRY <ArrowLeftIcon className="w-3 h-3 mx-2" /> IRT
              </div>

              <span className="text-gray-600">
                {exchangeRate?.amount?.toLocaleString()} تومان
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
