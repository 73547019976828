import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Switch,
} from "antd";
import { useParams } from "react-router-dom";
import { Bot } from "../../../services/Api/Bot";

export const EditBotForm = (props: any) => {
  const { botId } = useParams<any>();
  const [botDetails, setBotDetails] = useState<any>();
  const [activeTab, setActiveTab] = useState("general");
  const [generalForm] = Form.useForm();
  const [ccForm] = Form.useForm();

  const formBootstrap = async () => {
    try {
      const { data }: any = await Bot.getById(botId).json();
      if (!data) {
        return;
      }
      setBotDetails(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!botDetails) {
      return;
    }
    generalForm.setFieldsValue(botDetails);
  }, [botDetails]);

  useEffect(() => {
    (async () => {
      try {
        if (!botId) {
          return;
        }

        await formBootstrap();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [botId]);

  const handleGeneralDetails = async (values: any) => {
    try {
      const payload: any = {};
      for (const key of Object.keys(values)) {
        if (botDetails[key] !== values[key]) {
          payload[key] = values[key];
        }
      }
      if (Object.keys(payload).length > 0) {
        const { data }: any = await Bot.editById({ id: botId, payload }).json();

        if (!data) {
          return;
        }

        message.success("تنظیمات با موفقیت ذخیره شد.");
        formBootstrap();
        props?.refreshBots();
      }
    } catch (e) {
      console.error(e);
      // message.error(e?.message);
    }
  };

  const handleCreditCardUpdate = async (values: any) => {
    try {
      const { number, cvv, expiry } = values;
      const payload = {
        number: number.trim(),
        cvv,
        expiry,
      };

      const { data }: any = await Bot.editById({
        id: botId,
        payload: { card: payload },
      }).json();

      if (!data) {
        return;
      }

      message.success("تنظیمات با موفقیت ذخیره شد.");
    } catch (e) {
      console.error(e);
      // message.error(e.message);
    }
  };

  const handleCookieUpdate = async (values: any) => {
    try {
      const { cookies } = values;
      const parsedCookies: any[] = JSON.parse(cookies);
      const newCookies = parsedCookies.map((e) => {
        return {
          domain: e.domain,
          expires: e.expirationDate,
          httpOnly: e.httpOnly,
          name: e.name,
          path: e.path,
          secure: e.secure,
          value: e.value,
        };
      });

      const { data }: any = await Bot.editById({
        id: botId,
        payload: { cookies: newCookies },
      }).json();

      if (!data) {
        return;
      }

      message.success("تنظیمات با موفقیت ذخیره شد.");
    } catch (e) {
      console.error(e);
    }
  };

  const tabs = [
    {
      id: "general",
      title: "تنظیمات کلی",
    },
    {
      id: "credit-card",
      title: "تغییر کردیت کارت",
    },
    {
      id: "cookies",
      title: "تغییر کوکی",
    },
    {
      id: "soft-delete",
      title: "حذف بات",
    },
  ];

  const trimInput = ({ target }: { target: any }) => {
    const { id, value } = target;
    console.log(id, value);
    ccForm.setFieldsValue({ [id]: value.trim() });
  };

  const removeBot = async (botId: string) => {
    try {
      if (!botId) {
        return;
      }
      const { data }: any = await Bot.removeById(botId).json();
      if (!data) {
        return;
      }
      message.success("بات با موفقیت حذف شد.");
    } catch (e) {
      console.error(e);
    }
  };

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  return (
    <Modal
      visible={true}
      onCancel={() => props.history.push("/bots")}
      footer={false}
      width={width < 500 ? "90%" : "700px"}
    >
      <div className="flex flex-col lg:flex-row items-stretch">
        <div className="lg:w-48 ml-7 flex-none border-b lg:border-l border-gray-100 lg:pl-6">
          {tabs &&
            tabs.map(({ id, title }) => (
              <div
                className={`rounded-md px-4 py-3 mb-1 cursor-pointer ${
                  activeTab === id ? "bg-purple-50 text-purple-500" : ""
                }`}
                onClick={() => setActiveTab(id)}
              >
                {title}
              </div>
            ))}
        </div>
        <div className="flex-auto">
          {activeTab === "general" ? (
            <div className="flex flex-col">
              <Form
                form={generalForm}
                onFinish={handleGeneralDetails}
                className="flex-1 flex flex-col"
              >
                <Form.Item name="id" label="شناسه">
                  <Input disabled={true} />
                </Form.Item>
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <Form.Item name="name" label="نام">
                      <Input />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="isActive"
                    label="فعالسازی"
                    className=" border-gray-200 rounded-md"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <Form.Item
                  name="paymentMethod"
                  label="روش پرداختی"
                  className=" border-gray-200 rounded-md"
                >
                  <Radio.Group className="flex flex-wrap">
                    <Radio value="CREDIT_CARD">کردیت کارت</Radio>
                    <Radio value="TWITCH_CREDIT_CARD">کردیت کارت توییچ</Radio>
                    <Radio value="XSOLLA_SAVED_CARD">کارت ذخیره شده</Radio>
                    <Radio value="XSOLLA_GPAY">جی پی</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="proxyUri" label="پراکسی">
                  <Input />
                </Form.Item>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <Form.Item name="phoneNumber" label="شماره موبایل">
                    <Input />
                  </Form.Item>

                  <Form.Item name="owner" label="صاحب">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="run3ds"
                    label="3DS"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <div className="grid grid-cols-3 gap-4">
                      <Form.Item name="durability" label="دفعات تکرار">
                        <InputNumber className="w-full" />
                      </Form.Item>

                      <Form.Item name="waitTime" label="تایم اوت">
                        <InputNumber />
                      </Form.Item>

                      <Form.Item name="restoreTime" label="ساعت بازگشت">
                        <InputNumber />
                      </Form.Item>
                    </div>
                  </div>

                  <Form.Item
                    name="hasTwitchCard"
                    label="توییچ کارت"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <Form.Item
                    name="fillCard"
                    label="پر کردن کارت توسط بات"
                    className=" border-gray-200 rounded-md"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    name="recoverable"
                    label="قابل ریکاوری"
                    className=" border-gray-200 rounded-md"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                </div>

                <Button type="primary" htmlType="submit" className="h-10">
                  ثبت
                </Button>
              </Form>
            </div>
          ) : activeTab === "cookies" ? (
            <div className="flex flex-col">
              <Form onFinish={handleCookieUpdate}>
                <Form.Item name="cookies" label="کوکی">
                  <Input.TextArea style={{ direction: "ltr" }} />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="h-10 w-full"
                >
                  ثبت
                </Button>
              </Form>
            </div>
          ) : activeTab === "soft-delete" ? (
            <div className="flex flex-col">
              آیا از حذف بات اطمینان دارید؟
              <Button
                type="primary"
                danger
                onClick={() => removeBot(botId)}
                className="h-10 w-full mt-4"
              >
                حذف
              </Button>
            </div>
          ) : (
            <div className="flex flex-col">
              <Form form={ccForm} onFinish={handleCreditCardUpdate}>
                <Form.Item name="number" label="شماره کارت">
                  <Input onBlur={trimInput} />
                </Form.Item>
                <div className="grid grid-cols-2 gap-6">
                  <Form.Item name="cvv" label="CVV" className="">
                    <Input />
                  </Form.Item>
                  <Form.Item name="expiry" label="انقضا" className="">
                    <Input />
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="h-10 w-full"
                >
                  ثبت
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
