import React from "react";
import { Tabs } from "antd";
import { PaymentMethods } from "./components/PaymentMethods";
import { Subscriptions } from "./components/Subscriptions";
import { SmartBars } from "./components/SmartBars";

const { TabPane } = Tabs;

export const Settings = () => {
  return (
    <div>
      <h1 className="text-xl font-bold mb-3">تنظیمات</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="درگاه" key="1">
          <PaymentMethods />
        </TabPane>
        <TabPane tab="سابسکریپشن" key="2">
          <Subscriptions />
        </TabPane>
        <TabPane tab="اسمارت بار" key="3">
          <SmartBars />
        </TabPane>
      </Tabs>
    </div>
  );
};
