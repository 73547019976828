import React, { useEffect, useState } from "react";
import { Smartbar } from "../../../services/Api/Smartbar";
import { Button, Form, Input, message } from "antd";

// @ts-ignore
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

export const SmartBars = () => {
  const [latestBar, setLatestBar] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const { data }: any = await Smartbar.getLastest().json();
        if (!data) return;
        setLatestBar(data);
        console.log(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const handleAddSmartbar = async (values: { text: string; hours: number }) => {
    const { text, hours } = values;
    if (!hours) return;
    // @ts-ignore
    const validTillToTimestamp = +new Date()?.addHours(hours);
    const { data }: any = await Smartbar.add({
      text,
      validTill: validTillToTimestamp,
    }).json();
    if (!data) {
      return;
    }
    message.success("با موفقیت اضافه شد.");
  };

  return (
    <div className="mt-2.5">
      <div className="latest-smartbar mb-4">
        <h3 className="font-bold mb-2">آخرین اسمارت‌بار</h3>
        {latestBar && (
          <div
            dangerouslySetInnerHTML={{
              __html: latestBar?.text,
            }}
          ></div>
        )}
      </div>
      <hr className="my-4" />
      <div className="add-smartbar">
        <h3 className="font-bold mb-2">افزودن اسمارت‌بار</h3>
        <Form onFinish={handleAddSmartbar}>
          <Form.Item name="text" label="HTML">
            <Input.TextArea style={{ direction: "ltr" }} />
          </Form.Item>
          <Form.Item name="hours" label="فعال به مدت (ساعت)">
            <Input style={{ width: "50px" }} />
          </Form.Item>
          <Button
            type="primary"
            className="rounded py-1 px-5 h-10 shadow-sm"
            htmlType="submit"
          >
            افزودن
          </Button>
        </Form>
      </div>
    </div>
  );
};
