import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Radio } from "antd";
import { Bot } from "../../../services/Api/Bot";

export const AddBotForm = ({ addBot, setAddBot }: any) => {
  const [addForm] = Form.useForm();

  const handleAddBot = async (values: any) => {
    try {
      const { name, paymentMethod, cookies } = values;
      const parsedCookies: any[] = JSON.parse(cookies);
      const newCookies = parsedCookies.map((e) => {
        return {
          domain: e.domain,
          expires: e.expirationDate,
          httpOnly: e.httpOnly,
          name: e.name,
          path: e.path,
          secure: e.secure,
          value: e.value,
        };
      });

      const payload = {
        name,
        paymentMethod,
        cookies: newCookies,
      };

      const { data }: any = await Bot.add(payload).json();
      if (!data) {
        return;
      }
      message.success("بات با موفقیت اضافه شد.");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      visible={addBot}
      onCancel={() => setAddBot(false)}
      footer={false}
      width={500}
      destroyOnClose
    >
      <Form
        form={addForm}
        initialValues={{ paymentMethod: "CREDIT_CARD" }}
        onFinish={handleAddBot}
      >
        <Form.Item name="name" label="نام">
          <Input />
        </Form.Item>
        <Form.Item
          name="paymentMethod"
          label="روش پرداختی"
          className=" border-gray-200 rounded-md"
        >
          <Radio.Group>
            <Radio value="CREDIT_CARD">کردیت کارت</Radio>
            <Radio value="TWITCH_CREDIT_CARD">کردیت کارت توییچ</Radio>
            <Radio value="XSOLLA_SAVED_CARD">کارت ذخیره شده</Radio>
            <Radio value="TECK_CARD">تک کارت</Radio>
            <Radio value="XSOLLA_GPAY">جی پی</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="cookies" label="کوکی">
          <Input.TextArea style={{ direction: "ltr" }} />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="h-10 w-full">
          ثبت
        </Button>
      </Form>
    </Modal>
  );
};
